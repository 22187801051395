// sizes
$size-huge: 1900px;
$size-extra-large: 1281px;
$size-large: 1025px;
$size-medium: 768px;
$size-small: 481px;
$size-extra-small: 320px;

// colors
$primary-color: #364251;
$secondary-color: #8fb2d2;
$footer-color: rgba(227, 228, 229, 0.67);
$white: #ffffff;
$border-color: rgba(54, 66, 81, 0.78);
$shadow-color: rgba(54, 66, 81, 0.39);

// fonts
$primary-cursive-font-family: "Brush Script MT", cursive;
$primary-font-family: "Quicksand", sans-serif;

@import "src/theme";

.registry {
    .registryButton {
        font-family: "Open Sans", sans-serif;
        font-size: 30px;
        letter-spacing: 2px;
        text-decoration: none;
        text-transform: uppercase;
        color: #000;
        background-color: #97cfe5;
        cursor: pointer;
        border: 5px solid;
        // border: 1px solid #266DB6;
        padding: 0.25em 0.5em;
        box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
        position: relative;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }

    .registryButton:hover,
    .registryButton:active {
        outline: 0;
    }

    .registryButton:hover {
        background-color: #35b6e9;
        color: rgb(255, 255, 255);
        cursor: pointer;
    }

    .registryButton:active {
        box-shadow: 0px 0px 0px 0px;
        top: 5px;
        left: 5px;
    }

    @media (min-width: 768px) {
        .registryButton {
            padding: 0.25em 0.75em;
        }
    }
}
@import "src/theme";

.footer {
    background-color: $footer-color;
    font-size: 13px;
    font-weight: 300;
    p {
        margin-top: 0;
        margin-bottom: 0;
        &:nth-child(3) {
            font-size: 10px;
        }
    }
}

@import "src/theme";

.details {
    .announcement {
        font-family: $primary-cursive-font-family;
        font-weight: 100;
    }

    .rsvp {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        letter-spacing: 2px;
        text-decoration: none;
        text-transform: uppercase;
        color: #000;
        background-color: #97cfe5;
        cursor: pointer;
        border: 3px solid;
        // border: 1px solid #266DB6;
        padding: 0.25em 0.5em;
        box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
        position: relative;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }

    .rsvp:hover,
    .rsvp:active {
        outline: 0;
    }

    .rsvp:hover {
        background-color: #35b6e9;
        color: rgb(255, 255, 255);
        cursor: pointer;
    }

    .rsvp:active {
        box-shadow: 0px 0px 0px 0px;
        top: 5px;
        left: 5px;
    }

    @media (min-width: 768px) {
        .rsvp {
            padding: 0.25em 0.75em;
        }
    }

    .locBox {
        border:1px solid black;
        box-shadow: 0px 0px 0px 0px;
    }

    .locTitle {
        padding: 0.25em 0.75em;
        text-align: left
    }

    .date {
        padding-top: 40px;
        padding-bottom: 40px;
        font-family: $primary-font-family;
        font-size: 3.1em;
        font-weight: 100;
    }

    .location {
        font-family: $primary-cursive-font-family;
        font-weight: 100;

        a:link,
        a:visited,
        a:hover,
        a:link {
            color: black;
        }
    }

    .hashtag {
        font-family: $primary-font-family;
        font-weight: 300;
        font-size: 1.1em;
    }
}


// /* CSS */
// .button-51 {
//   background-color: transparent;
//   border: 1px solid #266DB6;
//   box-sizing: border-box;
//   color: #00132C;
//   font-family: "Avenir Next LT W01 Bold",sans-serif;
//   font-size: 16px;
//   font-weight: 700;
//   line-height: 24px;
//   padding: 16px 23px;
//   position: relative;
//   text-decoration: none;
//   user-select: none;
//   -webkit-user-select: none;
//   touch-action: manipulation;
// }

// .button-51:hover,
// .button-51:active {
//   outline: 0;
// }

// .button-51:hover {
//   background-color: transparent;
//   cursor: pointer;
// }

// .button-51:before {
//   background-color: #D5EDF6;
//   content: "";
//   height: calc(100% + 3px);
//   position: absolute;
//   right: -7px;
//   top: -9px;
//   transition: background-color 300ms ease-in;
//   width: 100%;
//   z-index: -1;
// }

// .button-51:hover:before {
//   background-color: #6DCFF6;
// }

// @media (min-width: 768px) {
//   .button-51 {
//     padding: 16px 32px;
//   }
// }